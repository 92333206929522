import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import CSFeatures from "../../components/Case-Studies/CSFeatures";
import CSHero from "../../components/Case-Studies/CSHero";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import MMReviews from "../../components/pages/modemagic/Reviews";
import {useMediaQuery} from "../../hooks/useMediaQuery";
import "../styles.scss";
import * as styles from "./styles.module.scss";

const CaseStudyPage = ({data: cmsData}) => {
  const styler = new StyleExtractor(styles);
  const isMobile = useMediaQuery("(max-width: 900px)");

  const seoData = cmsData.allSanitySeoMeta.nodes[0];
  const {reviews} = cmsData.allSanityModemagicPage.nodes[0];
  const {lastSection} = cmsData.allSanityModemagic3Page.nodes[0] ?? {};

  const {hero: heroData, featuredCS: featuredData} =
    cmsData.allSanityCaseStudiesPage.nodes[0] ?? {};

  return (
    <Layout
      type='modemagic'
      mmv3
      seoData={seoData}
      headerProps={{showMMCta: true}}
      className='full-bleed'
    >
      <StructuredData />
      <CSHero data={heroData} />
      <CSFeatures data={featuredData} />
      <section className={styler.get("reviewsWrap", "full-bleed")}>
        <MMReviews data={reviews} isMobile={isMobile} />
      </section>
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection data={lastSection} hideTicketSectionInDesktop />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanityCaseStudiesPage {
      nodes {
        hero {
          _rawHeading
          cta {
            text
            url
            idAttribute
          }
          _rawDescription
          video {
            ytId
          }
        }
        featuredCS {
          heading
          caseStudies {
            title
            image {
              image {
                asset {
                  gatsbyImageData
                }
              }
              alt
            }
            cta {
              text
              url
              idAttribute
            }
            ref {
              slug {
                current
              }
            }
          }
          heading
        }
      }
    }
    allSanityModemagic3Page: allSanityModemagicPage(
      filter: {page: {eq: "mmv3"}}
    ) {
      nodes {
        lastSection {
          _rawHeading
          _rawFinalHeading
          _rawDescription
          cta
          placeholder
        }
      }
    }
    allSanitySeoMeta(filter: {page: {eq: "case-studies"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityModemagicPage(filter: {page: {eq: "mm"}}) {
      nodes {
        reviews {
          _rawHeading
          _rawMobileHeading
          fReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
          sReview {
            person
            text
            designation
            image {
              asset {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            alt
          }
        }
      }
    }
  }
`;

export default CaseStudyPage;

import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import * as styles from "./styles.module.scss";

const CSFeatures = ({data}) => {
  const styler = new StyleExtractor(styles);

  const {heading, caseStudies} = data ?? {};

  return (
    <div className={styler.get("csFeaturesContainer", "full-bleed")}>
      <div className={styler.get("heading")}>{heading}</div>
      <div className={styler.get("cardsContainer")}>
        {caseStudies?.map((element) => {
          return (
            <div className={styler.get("card")}>
              <div className={styler.get("imageContainer")}>
                <OutboundLink
                  href={element.ref?.slug.current || element.cta?.url}
                  id={element.cta?.idAttribute}
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noopener noreferrer'
                >
                  <GatsbyImage
                    image={element.image?.image?.asset?.gatsbyImageData}
                    alt={element.image?.alt}
                    objectFit='cover'
                  />
                </OutboundLink>
              </div>
              <p>
                <OutboundLink
                  href={element.ref?.slug.current || element.cta?.url}
                  id={element.cta?.idAttribute}
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noopener noreferrer'
                >
                  {element.title}
                </OutboundLink>
              </p>
              <OutboundLink
                href={element.ref?.slug.current || element.cta?.url}
                id={element.cta?.idAttribute}
                target='_blank'
                referrerPolicy='no-referrer'
                rel='noopener noreferrer'
              >
                {element.cta?.text}
              </OutboundLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CSFeatures;

import {StyleExtractor} from "@kubric/utils";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import React from "react";

import SanityRichText from "../../commons/SanityRichText";
import * as styles from "./styles.module.scss";

const CSHero = ({data}) => {
  const styler = new StyleExtractor(styles);

  const {
    _rawHeading: heading,
    _rawDescription: description,
    cta,
    video,
  } = data ?? {};

  return (
    <div className={styler.get("csHeroContainer", "full-bleed")}>
      <SanityRichText
        renderContainerOnSingleChild
        blocks={heading}
        className={styler.get("heroHeading")}
      />
      <div className={styler.get("heroFeatures")}>
        <div className={styler.get("heroDescription")}>
          <SanityRichText renderContainerOnSingleChild blocks={description} />
          <OutboundLink
            href={cta?.url}
            id={cta?.idAttribute}
            target='_blank'
            referrerPolicy='no-referrer'
            rel='noopener noreferrer'
          >
            {cta?.text}
          </OutboundLink>
        </div>
        <div className={styler.get("heroVideoContainer")}>
          <iframe
            src={`https://www.youtube.com/embed/${video?.ytId}?feature=oembed`}
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen='true'
            title='Sports and Fitness Exchange Testimonial - Audiogram'
          />
        </div>
      </div>
    </div>
  );
};

export default CSHero;
